<template>
  <div>
    <div class="Login base-height">
      <div class="mainBox">
        <h1>
          <router-link to="/">
            <img src="@/assets/images/reviewmind/logo.png" alt="로고" />
          </router-link>
        </h1>
        <!-- <h2>로그인</h2> -->

        <div class="form login">
          <form>
            <fieldset>
              <legend>로그인</legend>
              <div class="cover bg">
                <label>이메일</label>
                <input v-model="email" type="email" placeholder="이메일 입력" />
                <!-- <em v-show="errMsg" class="errMsg">입력한 정보가 없거나 일치하지 않습니다.</em> -->
              </div>
              <div class="cover bg">
                <label>이름</label>
                <input v-model="username" type="text" placeholder="이름 입력" />
                <!-- <em v-show="errMsg" class="errMsg">입력한 정보가 없거나 일치하지 않습니다.</em> -->
              </div>
              <button type="button" class="btn full primary" @click="submit">
                비밀번호 찾기
              </button>
            </fieldset>
          </form>
        </div>
      </div>
      <aside class="flexL">
        <router-link to="/login" class="btn">로그인</router-link>
      </aside>
    </div>
  </div>
</template>

<script>
// import sha256 from "js-sha256";
import { createPassword } from "@/api/member";
import { mapState } from "vuex";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
import { format } from "@/mixins/format";
export default {
  mixins: [format, setNavbar, setFooter],
  data() {
    return {
      showSnackbar: false,
      visible: false,
      email: "",
      username: "",
      password: "",
      show: true,
      errMsg: false,
      snsLogin: true,
    };
  },
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState(["isLogin"]),
  },
  methods: {
    getPhoneMask(val) {
      let res = this.getMask(val);
      this.mobileNo = res;
      //서버 전송 값에는 '-' 를 제외하고 숫자만 저장
      // this.model.mobileNo = this.mobileNo.replace(/[^0-9]/g, "");
    },
    inNumber() {
      if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode == 8 ||
        event.keyCode == 37 ||
        event.keyCode == 39 ||
        event.keyCode == 46
      ) {
        return;
      } else {
        return false;
      }
    },
    removeChar() {
      if (
        event.keyCode == 8 ||
        event.keyCode == 46 ||
        event.keyCode == 37 ||
        event.keyCode == 39
      )
        return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    handleLogin(type) {
      if (type == "EMAIL") {
        this.snsLogin = false;
      }
    },
    submit() {
      if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
      } else if (this.username == "") {
        return alert("이름을 입력해 주세요.");
      }

      let data = {
        email: this.email,
        username: this.username,
      };
      createPassword(data).then((res) => {
        if (res.data.status === 200) {
          let message = res.data.message;
          alert(message);
          this.$router.push("/login");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
